import { FC } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { Box, Center, Text, Link as ChakraLink } from '@chakra-ui/react';
const NotFound404Logo = dynamic(() => import('ui/assets/NotFound404Logo'), {
  ssr: false
});
const ServerError505Logo = dynamic(() => import('ui/assets/ServerError505Logo'), {
  ssr: false
});
export interface ErrorPageProps {
  errorCode: string;
}
export const ErrorPage: FC<ErrorPageProps> = ({
  errorCode
}) => {
  const errorLogos = {
    404: NotFound404Logo,
    500: ServerError505Logo
  };
  const ErrorLogo = errorLogos[errorCode];
  return <Center flexDirection="column" height={{
    base: '65vh',
    md: '75vh'
  }} width="100%" marginX="auto" maxWidth="750px" textAlign="center" data-sentry-element="Center" data-sentry-component="ErrorPage" data-sentry-source-file="ErrorPage.tsx">
      <Box width="95%" data-sentry-element="Box" data-sentry-source-file="ErrorPage.tsx">
        <ErrorLogo data-sentry-element="ErrorLogo" data-sentry-source-file="ErrorPage.tsx" />
        <Text color="white" fontWeight="bold" fontSize="24px" fontFamily="codenext" marginTop={6} data-sentry-element="Text" data-sentry-source-file="ErrorPage.tsx">
          {'Something went wrong...'}
        </Text>

        <Box marginTop="40px" data-sentry-element="Box" data-sentry-source-file="ErrorPage.tsx">
          <NextLink href="/games" passHref data-sentry-element="NextLink" data-sentry-source-file="ErrorPage.tsx">
            <ChakraLink borderRadius="99px" backgroundColor="chonky.500" color="white" display="block" fontWeight="bold" fontFamily="codenext" marginX="auto" paddingY="12px" textDecoration="none" width="240px" _hover={{
            backgroundColor: 'chonky.700',
            textDecoration: 'none'
          }} data-sentry-element="ChakraLink" data-sentry-source-file="ErrorPage.tsx">
              {'Back to Home'}
            </ChakraLink>
          </NextLink>
        </Box>
      </Box>
    </Center>;
};